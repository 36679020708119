import { createSlice } from '@reduxjs/toolkit';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from 'axios'

const initialState= {
}

// Custom interceptor to remove woocommerce custom User-Agent (not allowed in Chrome/Safari)
axios.interceptors.request.use(function (config) {
  const { headers = {} } = config || {};
  if (headers['User-Agent']) delete config.headers['User-Agent']
  return config;
})

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    getBoardSuccess: (state, action) => {
      state[action.payload.id] = action.payload;
      return state;
      // return action.payload;
      // state.items = action.payload;
    },
  },
});

export const { getBoardSuccess } = boardSlice.actions;

export const fetchBoard = categorySearch => dispatch => {
  const WooCommerce = new WooCommerceRestApi({
    url: window._env_.REACT_APP_OOHRDER_ENDPOINT, // Your store URL
    consumerKey: window._env_.REACT_APP_OOHRDER_CONSUMER_KEY, // Your consumer key
    consumerSecret: window._env_.REACT_APP_OOHRDER_CONSUMER_SECRET, // Your consumer secret
    version: window._env_.REACT_APP_OOHRDER_VERSION // WooCommerce WP REST API version
  });
  WooCommerce.get(`products/categories?search=${categorySearch}`)
  .then((response) => {
    const category = response.data[0]
    WooCommerce.get(`products?category=${category.id}`)
    .then((response) => {
      const products = response.data
      const payload = {
        id: categorySearch,
        title: category.name,
        cta: category.description,
        url: `${window._env_.REACT_APP_OOHRDER_ENDPOINT}/${category.slug}`,
        items: products.map(product => {
          const html = product.short_description;
          const div = document.createElement("div");
          div.innerHTML = html;
          const name = div.textContent || div.innerText || "";
          return {
            name: name,
            description: product.name,
            price: product.price,
            regularPrice: product.regular_price,
            discount: (product.price/product.regular_price)*100,
            imageId: product.images[0].id,
            imageUrl: product.images[0].src,
          }
        })
      }
      dispatch(getBoardSuccess(payload));
    })
    .catch((error) => {
      console.log(error);
    });
  })
  .catch((error) => {
    console.error(error);
  });
};

export default boardSlice.reducer;
