import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const List = lazy(() => import("../features/list"));
const Grid = lazy(() => import("../features/grid"));
const Single = lazy(() => import("../features/single"));
const Triple = lazy(() => import("../features/triple"));

const Routes =  ({ childProps }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/board/:categorySearch" component={List} />
        <Route path="/list/:categorySearch/:theme?" component={List} />
        <Route path="/grid/:categorySearch/:theme?" component={Grid} />
        <Route path="/single/:categorySearch/:theme?" component={Single} />
        <Route path="/triple/:categorySearch/:theme?" component={Triple} />
        <Route path="*">Not Found</Route>
      </Switch>
    </Suspense>
  );
};
export default Routes;
