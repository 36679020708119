import { createSlice } from '@reduxjs/toolkit';

const params = new URLSearchParams(document.location.search);
// const playerId = params.get('playerId');
const containerId = params.get('iframeId') || params.get('containerId');
const duration = params.get('duration') ? params.get('duration') : '10000';

const initialState = null;

export const componentDataSlice = createSlice({
  name: 'componentData',
  initialState,
  reducers: {
    requestComponentData: (state, action) => {
      return { ...state, loading: true, ...action.payload };
    },
    createComponentData: (state, action) => {
      return { ...state, loading: false, ...action.payload };
    }
  },
});

export const { requestComponentData, createComponentData } = componentDataSlice.actions;

export default componentDataSlice.reducer;

const sendMessage = (message) => {
  if (window.top === window.self) {
    window.postMessage(message, '*');
  } else {
    window.parent.postMessage(message, '*');
  }
};

export const stopPlaying = () => (dispatch, getState) => {
  const { componentData: { containerId } } = getState();
  sendMessage({ event: 'end', frameId: containerId });
  dispatch(createComponentData({ running: false }));
};

export const initializePostMessages = (componentId) => (dispatch, getState) => {
  console.info('Initializing post messages: containerId=', componentId, containerId);
  const onPostMessage = (message) => {
    const { data } = message;
    // message (structure):
    // { 'data' :
    //   { 'containerId' : 'CONTAINER_IDENTIFIER_FROM_URL',
    //     'event' : 'componentData' || 'RefuelingProgress' || 'start' || 'stop'
    //   }
    // }

    // ignore messages from react
    if (data.containerId === containerId || data.frameId === containerId) {
      console.debug('Rx message: ', data);
      switch (data.event) {
        case 'componentData': {
          dispatch(createComponentData(data));
          const componentDataOld = getState().componentData;
          dispatch(createComponentData({ componentId: data.componentId, ...data.componentData }));
          if (componentDataOld.loading) {
            sendMessage({ event: 'loaded', frameId: containerId });
          }
          break;
        }
        case 'prebuffer': {
          dispatch(createComponentData({ prebuffer: true }));
          break;
        }
        case 'start': {
          sendMessage({ event: 'playing', frameId: containerId });
          setTimeout(() => {
            sendMessage({event: "end", frameId: containerId}, "*");
          }, duration);
          dispatch(createComponentData({ running: true }));

          break;
        }
        case 'stop': {
          dispatch(stopPlaying());
          break;
        }
        default:
      }
    }
  };
  window.addEventListener('message', onPostMessage);

  sendMessage({ event: 'loaded', frameId: containerId });
};
