import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';


import componentDataReducer from './componentDataSlice';
import boardReducer from './boardSlice';

const persistConfig = {
  key: 'oohrder',
  storage: storage,
  // whitelist: [''],
  // blacklist: [''],
};

const persistReducers = persistReducer(persistConfig, combineReducers({ componentData: componentDataReducer, board: boardReducer}));
const middleware = [...getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})];
const store = configureStore({
  reducer: persistReducers,
  middleware,
});

export const persistor = persistStore(store);

export default store;

