import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.info(
        'Received a service worker update, unregister old service worker and restart browser',
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: registration => {
    console.log('New service worker loaded');
  },
});
