import React from 'react';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

import './style/index.scss';
import Routes from "./Routes";
import store, { persistor } from "./store";

function App() {
  return (
      <div className="flex vertical-fill App">
        <Provider store={store}>
          <PersistGate loading={<div>Loading</div>} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </div>
  );
}

export default App;
